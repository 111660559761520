(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:ConfirmationCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.authentication')
  .controller('ConfirmationCtrl', ConfirmationCtrl);

  function ConfirmationCtrl(tokenConfirmation, $state, $stateParams, $mdToast, $filter) {
    var vm = this;
    vm.ctrlName = 'ConfirmationCtrl';
    if (tokenConfirmation === 'valid') {
      $state.go('authentication.registration', {token: $stateParams.token});
    } else if (tokenConfirmation === 'invalid') {
      $mdToast.show(
        $mdToast.simple()
        .textContent($filter('translate')('TOKEN_NOT_FOUND'))
        .position('bottom left')
        .hideDelay(3000));
      $state.go('authentication.login');
    }
  }
}());
